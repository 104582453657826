<template>
  <div :class="$style.errors">
    <div class="pt-4 pb-4 d-flex align-items-end mt-auto">
      <img src="resources/logo-dolarindo.png" alt="Dolarindo" width="200" />
      
    </div>
    <div class="pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-30" :class="$style.container">
      <div class="font-weight-bold mb-3">Page not found</div>
      <div>This page is deprecated, deleted, or does not exist at all</div>
      <div class="font-weight-bold font-size-70 mb-1">404 —</div>
      <router-link to="/" class="btn btn-outline-primary width-100">Go Back</router-link>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'AirError404',
}
</script>
<style lang="scss" module>
@import "@/components/system/Errors/style.module.scss";
</style>
